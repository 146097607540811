import * as React from 'react'
import { Tag, TagProps } from '../../../atoms/Tag'
import type { PropsWithoutChildren } from '../../../../types/helpers'

export type UpgradeTagProps = PropsWithoutChildren<Omit<TagProps, 'variant'>>

export const UpgradeTag: React.VFC<UpgradeTagProps> = (props) => (
  <Tag variant="highlight" {...props}>
    Upgrade
  </Tag>
)
